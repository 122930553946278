import { mapObject } from 'common/object';
import {
  Coordinates2,
  Coordinates3,
  Deck,
  DeckPosition,
  ExtraDeckAnnotation,
  GridAnnotation,
  Plate,
  RichAnnotationType,
  WellLocation,
  WellLocationOnDeckItem,
  WellType,
} from 'common/types/mix';
import { Bounds, Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import {
  DeckItemState,
  LidState,
  PlateState,
  TipwasteState,
} from 'common/ui/components/simulation-details/mix/MixState';
import { TipboxState } from 'common/ui/components/simulation-details/mix/TipboxState';
import { DUMMY_POSITION } from 'common/ui/components/simulation-details/PlateTransform';

// This file encapsulates information about positions of plates and wells
// within the deck.

export const WELL_BORDER_WIDTH_PX = 1.6;

/**
 * Constant used for mm to px conversion.
 */
const UI_SCALING_FACTOR = 3;

/** Size of the padding between position group heading and its contents */
const POSITION_GROUP_PADDING = 50;

// Point, in pixels
export type Point2D = { x: number; y: number };

// Can be passed as a style to a DOM element
export type RectPixels = {
  left: number;
  top: number;
  width: number;
  height: number;
};

// Can be an empty deck position, or it can contain a deck item.
export type DeckPositionRect = {
  deckPositionName: string; // e.g. TecanPos_1_4
  absolutePosInDeckPixels: RectPixels;
  zPosition: number;
  label?: string; // For use with labelled empty deck positions only
  extraDeck?: ExtraDeckAnnotation;
};

export type PositionGroupRect = {
  absolutePosInDeckPixels: RectPixels;
  description: string;
  headingColour: string;
  label: string;
};

export type GridAnnotationRect = {
  absolutePosInDeckPixels: RectPixels;
} & Omit<GridAnnotation, 'position' | 'size'>;

type StageAnnotationRegion = Pick<RectPixels, 'left' | 'width'>;

/**
 * Encapsulates information about positions of plates on the deck
 * and the wells within the plates.
 */
export default class DeckLayout {
  /** Size of the deck in the UI, in pixels. */
  readonly deckBounds: { width: number; height: number };
  /** All available locations on the deck, in pixels. */
  private readonly deckPositions: {
    [deckPositionName: string]: DeckPositionRect;
  };
  /**
   * PositionGroup annotation visualises plate groups used in Multi I/O plate
   * dispenser experiments.
   *
   * PositionGroup could be either a group of Source plates and (or) Destination plates.
   * Or it could group 1 input & 1 output plate to visualise dispenser liquid handling.
   */
  private readonly deckPositionGroupAnnotations?: {
    [annotationName: string]: PositionGroupRect;
  };
  /** All grid annotations to add as extra information, in pixels */
  private readonly gridAnnotations?: {
    [annotationName: string]: GridAnnotationRect;
  };
  /**
   * Stage annotation divides the Simulation preview workspace into sections
   * for multi-stage simulations
   */
  private readonly stageAnnotations?: {
    [annotationName: string]: StageAnnotationRegion;
  };

  constructor(public deck: Deck) {
    this.deckPositions = mapObject(
      deck.before.positions,
      (deckPositionName, posFromBackend: DeckPosition) => ({
        deckPositionName,
        label: posFromBackend.label,
        zPosition: 'z_mm' in posFromBackend.position ? posFromBackend.position.z_mm : 0,
        absolutePosInDeckPixels: convertCoordinatesToPixels(
          posFromBackend.position,
          posFromBackend.size,
        ),
      }),
    );

    // Store all positions we know about to be able to compute the bounds properly
    const knownPositions = Object.values(this.deckPositions).map(
      pos => pos.absolutePosInDeckPixels,
    );

    if (deck.before.rich_annotations) {
      this.deckPositionGroupAnnotations = {};
      this.gridAnnotations = {};
      this.stageAnnotations = {};
      for (const key in deck.before.rich_annotations) {
        const value = deck.before.rich_annotations[key];
        let absolutePosInDeckPixels: RectPixels;
        switch (value.annotation_type) {
          case RichAnnotationType.GRID:
            absolutePosInDeckPixels = convertCoordinatesToPixels(
              value.grid_annotation.position,
              value.grid_annotation.size,
            );
            this.gridAnnotations[key] = {
              absolutePosInDeckPixels: absolutePosInDeckPixels,
              label: value.grid_annotation.label,
              important: value.grid_annotation.important,
              left: value.grid_annotation.left,
              right: value.grid_annotation.right,
              offDeck: value.grid_annotation.offDeck,
            };
            knownPositions.push(absolutePosInDeckPixels);
            break;
          case RichAnnotationType.EXTRA_DECK:
            absolutePosInDeckPixels = convertCoordinatesToPixels(
              value.extra_deck_annotation.position,
              value.extra_deck_annotation.size,
            );
            this.deckPositions[key] = {
              deckPositionName: key,
              absolutePosInDeckPixels: absolutePosInDeckPixels,
              zPosition: value.extra_deck_annotation.position.z_mm,
              label: value.extra_deck_annotation.label,
              extraDeck: value.extra_deck_annotation,
            };
            knownPositions.push(absolutePosInDeckPixels);
            break;
          case RichAnnotationType.STAGE:
            this.stageAnnotations[key] = {
              left: millimetersToPixels(value.stage_annotation.x_mm),
              width: millimetersToPixels(value.stage_annotation.width_mm),
            };
            break;
          case RichAnnotationType.GROUP:
            absolutePosInDeckPixels = convertCoordinatesToPixels(
              value.positions_group_annotation.position,
              value.positions_group_annotation.size,
            );
            absolutePosInDeckPixels.top -= POSITION_GROUP_PADDING;
            absolutePosInDeckPixels.height += POSITION_GROUP_PADDING;

            this.deckPositionGroupAnnotations[key] = {
              absolutePosInDeckPixels,
              description: value.positions_group_annotation.description,
              headingColour: getPositionGroupColour(value),
              label: value.positions_group_annotation.label,
            };
            break;
        }
      }
    }

    this.deckBounds = getDeckBounds(knownPositions);
  }

  /**
   * Returns physical position of the deck item on the deck, in pixels.
   *
   * This function has two overloads:
   * - when given a lid, a the returned geometry describes the position and size of the
   *   lid.
   * - when given a plate, tipwaste, or tipbox, the returned geometry will also include
   *   well geometries.
   */
  getCurrentGeometry(deckItem: LidState): DeckItemGeometry;
  getCurrentGeometry(
    deckItem: PlateState | TipwasteState | TipboxState,
  ): DeckItemWithWellsGeometry;
  getCurrentGeometry(
    deckItem: DeckItemState,
  ): DeckItemGeometry | DeckItemWithWellsGeometry;
  getCurrentGeometry(
    deckItem: DeckItemState,
  ): DeckItemGeometry | DeckItemWithWellsGeometry {
    // The deck item might have moved over time. This is its current location.
    const currentDeckPositionOfItem =
      this.deckPositions[deckItem.currentDeckPositionName];
    if (!currentDeckPositionOfItem) {
      throw new Error('Unknown deck position name: ' + deckItem.currentDeckPositionName);
    }
    return deckItem.kind === 'lid' || deckItem.kind === 'cap'
      ? new DeckItemGeometry(deckItem, currentDeckPositionOfItem.absolutePosInDeckPixels)
      : new DeckItemWithWellsGeometry(
          deckItem,
          currentDeckPositionOfItem.absolutePosInDeckPixels,
        );
  }

  /**
   * Given a plate and a well position inside it, return a position in the well relative
   * to the deck.
   *
   * `posInWell` can be specified as coordinates between 0 and 1 (where 0.5 is the well's
   * center) to determine the returned coordinate in the well. This is used in the preview
   * for spacing out edges going to/from a well.
   */
  getWellPosition(
    deckItems: readonly DeckItemState[],
    location: WellLocationOnDeckItem,
    posInWell: Position2d = { x: 0.5, y: 0.5 },
  ): Point2D {
    const deckItemForLocation = deckItems.find(
      deckItem => deckItem.id === location.deck_item_id,
    );
    if (!deckItemForLocation) {
      // Should never happen (only happen if antha-core produces invalid actions.json)
      throw new Error('Unknown deck item ' + location.deck_item_id);
    }
    if (deckItemForLocation.kind === 'lid') {
      // Should never happen as getWellPosition is not called from the LidView component
      throw new Error(
        `Cannot get well position for lid ${location.deck_item_id}. Lids do not have wells.`,
      );
    }
    if (deckItemForLocation.kind === 'cap') {
      // Should never happen as getWellPosition is not called from the CapView component
      throw new Error(
        `Cannot get well position for cap ${location.deck_item_id}. Caps do not have wells.`,
      );
    }
    const deckItemGeometry = this.getCurrentGeometry(deckItemForLocation);
    const wellRectInPlate = deckItemGeometry.getWellRect(location.col, location.row);
    const deckItemLeft = deckItemGeometry.absolutePosInDeckPixels.left;
    const deckItemTop = deckItemGeometry.absolutePosInDeckPixels.top;
    const bw = WELL_BORDER_WIDTH_PX;
    return {
      x: deckItemLeft + wellRectInPlate.left + posInWell.x * wellRectInPlate.width + bw,
      y: deckItemTop + wellRectInPlate.top + posInWell.y * wellRectInPlate.height + bw,
    };
  }

  getAllGridPositions(): GridAnnotationRect[] {
    return !this.gridAnnotations ? [] : Object.values(this.gridAnnotations);
  }

  /**
   * Returns all deck positions including:
   * - Carriers
   * - Input Plates
   * - Output Plates
   * - Tip Boxes
   * - Tip Wastes
   * - Temporary Locations
   */
  getAllDeckPositions(): DeckPositionRect[] {
    const positions = Object.values(this.deckPositions);
    /**
     * Some devices support deck items at different z positions. E.g. Fluent allows deck
     * items to be positioned below the deck. Order DOM elements to ensure items with
     * higher z positions are rendered on top.
     */
    return [...positions].sort((a, b) => {
      /**
       * Here we send to back deck positions with labels (plate carrier annotations).
       * They should go behind ordinary deck positions regardless of z-position.
       * Within each of these two groups, deck positions should go on top based on z-position.
       */
      if (a.label && !b.label) {
        return -1;
      } else if (!a.label && b.label) {
        return 1;
      } else {
        return a.zPosition - b.zPosition;
      }
    });
  }

  /**
   * `Position Group` is a set of plate positions grouped together without
   * using a Carrier.
   *
   * Currently the only case for Position Group is a dispenser staging area.
   * However, it can be used for other purposes as well.
   */
  getAllPositionGroups(): PositionGroupRect[] {
    return !this.deckPositionGroupAnnotations
      ? []
      : Object.values(this.deckPositionGroupAnnotations);
  }

  /**
   * `Stage Region` is a region on the Workspace allocated specifically for
   * the particular simulation stage. It has only an `x` coordinate (`left`) and a `width`.
   */
  getAllStageRegions(): StageAnnotationRegion[] {
    return !this.stageAnnotations ? [] : Object.values(this.stageAnnotations);
  }

  /**
   * Get the coordinates of the center of a deck position
   */
  getDeckPositionCenter(deckPositionName: string): Point2D {
    const location = this.deckPositions[deckPositionName];
    if (!location) {
      // Should never happen (only happen if antha-core produces invalid actions.json)
      throw new Error('Unknown deck position name ' + deckPositionName);
    }
    const dimensions = location.absolutePosInDeckPixels;
    return {
      x: dimensions.left + dimensions.width / 2,
      y: dimensions.top + dimensions.height / 2,
    };
  }
}

function getPositionGroupColour(value: {
  positions_group_annotation: { heading_colour: string };
}): string {
  const headingColour = value.positions_group_annotation.heading_colour;
  switch (headingColour) {
    case 'on_device':
      return Colors.BLUE_5;
    case 'off_device':
      return Colors.GREY_30;
    default:
      throw new Error('Unknown value of "heading_colour": ' + headingColour);
  }
}

function convertCoordinatesToPixels(
  // The z values are currently ignored in the UI
  position: Coordinates3 | Coordinates2, // Position of the slot on the deck
  size: Coordinates2, // Size of the slot
): RectPixels {
  return convertMillimetersToPixels({ x_mm: position.x_mm, y_mm: position.y_mm }, size);
}

function convertMillimetersToPixels(
  topLeft: Coordinates2,
  size: Coordinates2,
): RectPixels {
  return {
    left: topLeft.x_mm * UI_SCALING_FACTOR,
    top: topLeft.y_mm * UI_SCALING_FACTOR,
    width: size.x_mm * UI_SCALING_FACTOR,
    height: size.y_mm * UI_SCALING_FACTOR,
  };
}

export function pixelsToMillimeters(px: number) {
  return px / UI_SCALING_FACTOR;
}
export function millimetersToPixels(mm: number) {
  return mm * UI_SCALING_FACTOR;
}

// Based on the positions of the deck items, get a bounding box that contains
// all of them. This is needed for computing the size of the svg element for
// rendering edges.
function getDeckBounds(positions: RectPixels[]) {
  // Return right-most and bottom-most edge out of all deck items
  return {
    height: Math.max(...positions.map(p => p.top + p.height)),
    width: Math.max(...positions.map(p => p.left + p.width)),
  };
}

type DeckItemRotationDegrees = 0 | 90 | 180 | 270;

/**
 * Encapsulates information about the position of a single deck item.
 */
export class DeckItemGeometry {
  public readonly id: string;
  constructor(
    private readonly deckItem: DeckItemState,
    public readonly absolutePosInDeckPixels: RectPixels,
  ) {
    this.id = deckItem.id;
  }
  getCurrentRotationDegrees(): DeckItemRotationDegrees {
    if (
      this.deckItem.currentRotationDegrees !== 0 &&
      this.deckItem.currentRotationDegrees !== 90 &&
      this.deckItem.currentRotationDegrees !== 180 &&
      this.deckItem.currentRotationDegrees !== 270
    ) {
      throw new Error(
        `Plate ${this.deckItem.name} has an unsupported rotation of ${this.deckItem.currentRotationDegrees}`,
      );
    }
    return this.deckItem.currentRotationDegrees;
  }
}

/**
 * Most deck items have well geometries, including plates, tip wastes and tip boxes.
 */
export class DeckItemWithWellsGeometry extends DeckItemGeometry {
  public readonly rows: number;
  public readonly columns: number;
  public readonly wellType: WellType;
  private labelFontSize: number | null = null;
  constructor(
    private readonly deckItemWithWells: PlateState | TipwasteState | TipboxState,
    public readonly absolutePosInDeckPixels: RectPixels,
  ) {
    super(deckItemWithWells, absolutePosInDeckPixels);
    this.rows = deckItemWithWells.rows;
    this.columns = deckItemWithWells.columns;
    this.wellType = deckItemWithWells.well_type;
  }

  /**
   * Get all wells within a given bounding box (in pixels) relative to the top
   * left of the plate.
   *
   * This currently does not take into account any transforms to the plate (e.g.
   * rotation, translation).
   *
   * @param param0 Bounds (in pixels) to get wells from
   * @param deadZone The amount of overlap (as a proportion of row height /
   * column width) required to consider a well within the bounds. For drag
   * selection, this prevents a well from being selected while the mouse is in
   * the space between wells. So the mouse will need to be dragged a small
   * distance into the well (and its surrounding padding) before it gets added
   * to the selection.
   */
  getWellsInBounds({ x1, y1, x2, y2 }: Bounds, deadZone: number = 0.2): WellLocation[] {
    const di = this.deckItemWithWells;
    // Location (in mm) of top left well on the plate. well_start is the center
    // of the well so we have to subtract half of well width/height to get the
    // left top corner of the well.
    const offsetX = di.well_start.x_mm - di.well_dimensions.x_mm / 2;
    const offsetY = di.well_start.y_mm - di.well_dimensions.y_mm / 2;

    const colWidth = di.well_offset.x_mm;
    const rowHeight = di.well_offset.y_mm;

    // Compute the columns/rows for the bounds
    const colAtX1 = (pixelsToMillimeters(x1) - offsetX) / colWidth;
    const colAtX2 = (pixelsToMillimeters(x2) - offsetX) / colWidth;
    const rowAtY1 = (pixelsToMillimeters(y1) - offsetY) / rowHeight;
    const rowAtY2 = (pixelsToMillimeters(y2) - offsetY) / rowHeight;

    // Round everything down. If a horizontal point at 1.8, then it is within
    // column 1. Add the deadzone prevents a well from being selected while the
    // mouse is in the space between wells. The selection goes up to and
    // includes colEnd/rowEnd
    const colStart = Math.floor(Math.min(colAtX1, colAtX2) + deadZone);
    const rowStart = Math.floor(Math.min(rowAtY1, rowAtY2) + deadZone);
    const colEnd = Math.floor(Math.max(colAtX1, colAtX2) - deadZone);
    const rowEnd = Math.floor(Math.max(rowAtY1, rowAtY2) - deadZone);

    // Clip the positions so they do not exceed the actual wells on the plate
    const clippedColEnd = Math.min(this.columns - 1, colEnd);
    const clippedRowEnd = Math.min(this.rows - 1, rowEnd);
    const clippedColStart = Math.min(Math.max(0, colStart), clippedColEnd);
    const clippedRowStart = Math.min(Math.max(0, rowStart), clippedRowEnd);

    const wells: WellLocation[] = [];
    for (let col = clippedColStart; col <= clippedColEnd; col++) {
      for (let row = clippedRowStart; row <= clippedRowEnd; row++) {
        wells.push({ col, row });
      }
    }
    return wells;
  }

  /**
   * Returns the x and y offset of start of the wells to the edge of the plates. This is measured
   * as the distance from the top/left of the plate to the edge of the first row/column
   * of wells.
   */
  getWellStartOffsets(): {
    yWellStartOffset: number;
    xWellStartOffset: number;
  } {
    const di = this.deckItemWithWells;
    const xOffset = di.well_start.x_mm - di.well_dimensions.x_mm / 2;
    const yOffset = di.well_start.y_mm - di.well_dimensions.y_mm / 2;
    return {
      yWellStartOffset: yOffset * UI_SCALING_FACTOR,
      xWellStartOffset: xOffset * UI_SCALING_FACTOR,
    };
  }

  /**
   * Given plate size and a location inside the plate, get the position of the
   * well relative to that plate.
   */
  getWellRect(col: number, row: number): RectPixels {
    const di = this.deckItemWithWells;
    const wellWidth = di.well_dimensions.x_mm;
    const wellHeight = di.well_dimensions.y_mm;
    const wellRect: Readonly<RectPixels> = {
      // well_start is the center of the well so we have to subtract half of
      // well width, height to get the left, top corner
      left:
        (di.well_start.x_mm + col * di.well_offset.x_mm - wellWidth / 2) *
        UI_SCALING_FACTOR,
      top:
        (di.well_start.y_mm + row * di.well_offset.y_mm - wellHeight / 2) *
        UI_SCALING_FACTOR,
      width: wellWidth * UI_SCALING_FACTOR,
      height: wellHeight * UI_SCALING_FACTOR,
    };
    switch (this.getCurrentRotationDegrees()) {
      case 0:
        // Don't rotate the plate
        return wellRect;
      case 90:
        // Rotate the plate by 90 degrees
        return {
          left: this.absolutePosInDeckPixels.width - wellRect.top - wellRect.height,
          top: wellRect.left,
          width: wellRect.height,
          height: wellRect.width,
        };
      case 180:
        // Rotate the plate by 180 degrees
        return {
          left: this.absolutePosInDeckPixels.width - wellRect.left - wellRect.width,
          top: this.absolutePosInDeckPixels.height - wellRect.top - wellRect.height,
          width: wellRect.width,
          height: wellRect.height,
        };
      case 270:
        // Rotate the plate by 270 degrees
        return {
          left: wellRect.top,
          top: this.absolutePosInDeckPixels.height - wellRect.left - wellRect.width,
          width: wellRect.height,
          height: wellRect.width,
        };
    }
  }

  /**
   * It actually gets confusing what is a column and what is a row, once you start
   * thinking about rotation, too.
   * The "x" always means the horizontal direction in the normal orientation, when
   * the plate is not rotated. That is, the axis with the labels '1', '2', etc. on the
   * plastic labware.
   */
  getLabelPosForX(col: number) {
    const labelSize = this.getLabelFontSize();
    const wellRectAtCol = this.getWellRect(col, 0);
    switch (this.getCurrentRotationDegrees()) {
      case 0:
        return {
          centerX:
            wellRectAtCol.left +
            // Add half the well size, to align with well center
            wellRectAtCol.width / 2,
          // Vertical center of text this far from the top
          centerY: wellRectAtCol.top / 2,
          labelSize,
        };
      case 90:
        return {
          // Midpoint between well right edge and deck right edge
          centerX:
            (wellRectAtCol.left +
              wellRectAtCol.width +
              this.absolutePosInDeckPixels.width) /
            2,
          centerY: wellRectAtCol.top + wellRectAtCol.height / 2,
          labelSize,
        };
      case 180:
        return {
          centerX: wellRectAtCol.left + wellRectAtCol.width / 2,
          centerY: this.absolutePosInDeckPixels.height - wellRectAtCol.height / 2,
          labelSize,
        };
      case 270:
        return {
          centerX: wellRectAtCol.left / 2,
          centerY: wellRectAtCol.top + wellRectAtCol.height / 2,
          labelSize,
        };
    }
  }

  /**
   * It actually gets confusing what is a column and what is a row, once you start
   * thinking about rotation, too.
   * The "y" always means the vertical direction in the normal orientation, when
   * the plate is not rotated. That is, the axis with the labels 'A', 'B', etc. on the
   * plastic labware.
   */
  getLabelPosForY(row: number) {
    const labelSize = this.getLabelFontSize();
    const wellRectAtRow = this.getWellRect(0, row);
    switch (this.getCurrentRotationDegrees()) {
      case 0:
        return {
          // This far from the left edge
          centerX: wellRectAtRow.left / 2,
          // Vertical center of text this far from the top
          centerY: wellRectAtRow.top + wellRectAtRow.height / 2,
          labelSize,
        };
      case 90:
        return {
          centerX: wellRectAtRow.left + wellRectAtRow.width / 2,
          centerY: wellRectAtRow.top / 2,
          labelSize,
        };
      case 180:
        return {
          // Midpoint between well right edge and deck right edge
          centerX:
            (wellRectAtRow.left +
              wellRectAtRow.width +
              this.absolutePosInDeckPixels.width) /
            2,
          centerY: wellRectAtRow.top + wellRectAtRow.height / 2,
          labelSize,
        };
      case 270:
        return {
          centerX: wellRectAtRow.left + wellRectAtRow.width / 2,
          centerY: wellRectAtRow.top + wellRectAtRow.height + labelSize,
          labelSize,
        };
    }
  }

  /**
   * Returns dimentions of the deck item based on its absolute position on deck and its wells
   */
  getDimensions(): Pick<RectPixels, 'width' | 'height'> {
    const lastWellRect = this.getWellRect(this.columns - 1, this.rows - 1);
    const padding = 10;

    return {
      width: Math.max(
        this.absolutePosInDeckPixels.width,
        lastWellRect.left + lastWellRect.width + padding,
      ),
      height: Math.max(
        this.absolutePosInDeckPixels.height,
        lastWellRect.top + lastWellRect.height + padding,
      ),
    };
  }

  /**
   * Size of a label on the edge of the plate.
   * The size is variable because some plates have many wells and those wells
   * are small and close together. To avoid labels overlapping, those labels
   * have to be small too (like on the real physical plate).
   */
  private getLabelFontSize() {
    // Cache this because the result only depends on readonly fields initialised
    // in the constructor.
    if (!this.labelFontSize) {
      const firstWellRect = this.getWellRect(0, 0);
      const leftEdgeSpace = firstWellRect.left;
      const topEdgeSpace = firstWellRect.top;
      const wellWidth = this.deckItemWithWells.well_dimensions.x_mm;
      const wellHeight = this.deckItemWithWells.well_dimensions.y_mm;
      const maxFontSize = 12;
      const minFontSize = 6;
      // The font shouldn't be larger than any of these values. We want the
      // label to fit inside the space on the edge of the plate, and we also want
      // the label stay within well bounds (prevent overlap with neighbour).
      this.labelFontSize = Math.max(
        minFontSize,
        Math.min(maxFontSize, leftEdgeSpace, topEdgeSpace, wellWidth * 2, wellHeight * 2),
      );
    }
    return this.labelFontSize;
  }
}

/**
 * This dummy layout is used to render the WellSelector.
 *
 * To re-use all the code in DeckLayout, geometry, etc when we don't have access to
 * a deck from the backend, we need to artificially create one.
 * Since the WellSelector is not absolutely positioned, the physical positions
 * below don't really matter: we just want to inject the plate in `position_1`.
 */
const DUMMY_VERSION = '1.0';
export const DUMMY_DECK: Deck = {
  before: {
    positions: {
      // One dummy deck position is enough. We only need a dummy deck with
      // a single position for the plate.
      [DUMMY_POSITION]: {
        position: { x_mm: -10.494, y_mm: -7.727, z_mm: -82.035 },
        size: { x_mm: 127.76, y_mm: 85.48 },
      },
    },
  },
  after: {
    // "after" positions are not needed for the purpose of the test.
    positions: {},
  },
  version: DUMMY_VERSION,
};

export function getLayoutForWellSelector(plate: Plate) {
  const deck = { ...DUMMY_DECK };

  // A bit of a hack: Add the WellSelector plate at position 1.
  deck.before.positions[DUMMY_POSITION].item = plate;
  return new DeckLayout(deck);
}

export function getLayoutForWellSelectorWithPlateDimensions(plate: Plate) {
  const deck = { ...DUMMY_DECK };
  deck.before.positions[DUMMY_POSITION].size = {
    x_mm: plate.dimensions.x_mm,
    y_mm: plate.dimensions.y_mm,
  };

  // A bit of a hack: Add the WellSelector plate at position 1.
  deck.before.positions[DUMMY_POSITION].item = plate;
  return new DeckLayout(deck);
}
