import React from 'react';

import { Link } from 'react-router-dom';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import { MockProtocols } from 'client/app/apps/protocols/useGetData';
import { protocolsRoutes } from 'client/app/lib/nav/actions';

export default function ProtocolsScreen() {
  return (
    <ExperimentsNavigation title="Protocols">
      <h1>List Protocols</h1>
      <h2>Use Protocols:</h2>
      {MockProtocols.map(protocol => {
        return (
          <Link
            key={protocol.id}
            to={protocolsRoutes.editProtocolInstance.getPath({ id: protocol.id })}
          >
            {protocol.name}
          </Link>
        );
      })}
    </ExperimentsNavigation>
  );
}
