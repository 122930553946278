import React from 'react';

import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineOutlined';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatDateTime } from 'common/lib/format';

type Props = {
  title: string;
  description: string;
  createdBy: string;
  createdAt: string;
};

export const ProtocolInstanceHeader = ({
  title,
  description,
  createdBy,
  createdAt,
}: Props) => {
  const formattedDate = formatDateTime(new Date(createdAt));

  return (
    <Wrapper>
      <Typography variant="h1">{title}</Typography>
      <Details>
        <Detail label="Creator" icon={<PersonIcon fontSize="small" />}>
          <Typography variant="subtitle2">{createdBy}</Typography>
        </Detail>
        <Detail label="Created on" icon={<CalendarIcon fontSize="small" />}>
          <Typography variant="subtitle2">{formattedDate}</Typography>
        </Detail>
        <Detail label="Description" icon={<InfoIcon fontSize="small" />}>
          <Typography variant="subtitle2" noWrap>
            {description}
          </Typography>
        </Detail>
      </Details>
    </Wrapper>
  );
};

type DetailProps = {
  label: string;
  icon: React.ReactNode;
  children: React.ReactChild;
};

const Detail = ({ label, icon, children }: DetailProps) => {
  return (
    <DetailWrapper>
      <Typography variant="body1">{label}</Typography>
      <DetailLabel>
        <IconWrapper>{icon}</IconWrapper>
        {children}
      </DetailLabel>
    </DetailWrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 7),
  gap: theme.spacing(5),
}));
const DetailWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '160px',
  gap: theme.spacing(2),
}));
const IconWrapper = styled('div')({
  display: 'flex',
});
const Details = styled('div')({
  display: 'flex',
  gap: '24px',
});
const DetailLabel = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'end',
});
