import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type Props = {
  handleOnSimulate: () => void;
};

export const EditProtocolInstanceHeader = ({ handleOnSimulate }: Props) => {
  return (
    <Wrapper>
      <Typography variant="h1">Edit your protocol</Typography>
      <Fab onClick={handleOnSimulate} color="primary" size="medium" variant="extended">
        Simulate protocol
      </Fab>
    </Wrapper>
  );
};

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
}));
