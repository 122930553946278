import React, { useState } from 'react';

import PlaceHolderIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import ButtonBase from '@mui/material/ButtonBase';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import StandaloneToolDialog, {
  StandaloneTool,
} from 'client/app/components/nav/StandaloneToolsDialog';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';

export default function StandaloneToolsButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [dialogTool, setDialogTool] = useState<StandaloneTool | null>(null);
  const isDialogOpen = dialogTool !== null;

  return (
    <>
      {isDialogOpen ? (
        <StandaloneToolDialog
          onClose={() => setDialogTool(null)}
          open={isDialogOpen}
          tool={dialogTool}
        />
      ) : null}
      <Tooltip title="Tools">
        <ButtonBase
          data-heap-tracking="breadcrumbs-standalone-tools-button"
          onClick={handleClickButton}
          disableRipple
        >
          <CalculateIcon color="primary" />
        </ButtonBase>
      </Tooltip>

      <StyledMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuitem
          onClick={() => {
            setDialogTool(StandaloneTool.STOCK_DILUTION);
            handleCloseMenu();
          }}
          data-heap-tracking="standalone-tools-list-stock-dilution"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Stock Dilution</StyledTypography>
        </StyledMenuitem>
        <StyledMenuitem
          onClick={() => {
            setDialogTool(StandaloneTool.ABS_TRANS_CONVERTER);
            handleCloseMenu();
          }}
          data-heap-tracking="standalone-tools-list-absorbance-transmittance"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Absorbance/Transmittance</StyledTypography>
        </StyledMenuitem>
        <StyledMenuitem
          onClick={() => {
            setDialogTool(StandaloneTool.MASS_MOLARITY);
            handleCloseMenu();
          }}
          data-heap-tracking="standalone-tools-list-mass-molarity"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Mass/Molarity</StyledTypography>
        </StyledMenuitem>
        <StyledMenuitem
          onClick={() => {
            setDialogTool(StandaloneTool.DENSITY);
            handleCloseMenu();
          }}
          data-heap-tracking="standalone-tools-list-density-calculator"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Density Calculator</StyledTypography>
        </StyledMenuitem>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    padding: theme.spacing(4),
    borderRadius: 12,
  },
  [`& .${menuClasses.list}`]: {
    padding: 0,
    display: 'grid',
    rowGap: theme.spacing(4),
  },
}));

const StyledMenuitem = styled(MenuItem)({
  borderRadius: 8,
  borderColor: Colors.GREY_20,
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
}));
