import React from 'react';

import { styled } from '@mui/material/styles';

import { EditProtocolInstance } from 'client/app/apps/protocols/EditProtocolInstance';
import { ProtocolInstanceHeader } from 'client/app/apps/protocols/ProtocolInstanceHeader';
import { useGetDataAndInitialiseState } from 'client/app/apps/protocols/useGetData';

type Props = {
  id: string;
};

export default function EditProtocolInstanceScreen({ id: protocolId }: Props) {
  const { isLoading, protocol, protocolInstance, workflowSchema } =
    useGetDataAndInitialiseState(protocolId);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Wrapper>
      <ProtocolInstanceHeader
        title={protocol.name}
        description={protocol.description}
        createdBy={protocolInstance.createdBy}
        createdAt={protocolInstance.createdAt}
      />
      <EditProtocolInstance steps={protocol.steps} workflowSchema={workflowSchema} />
    </Wrapper>
  );
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
}));
