import React, { useState } from 'react';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';

import { validateAndCalculateMolecularWeight } from 'client/app/apps/standalone-tools/mass-molarity-calcuator/atomicWeights';
import TextField from 'common/ui/filaments/TextField';

type Props = {
  molecularWeight: string;
  onChange: (molecularWeight: string) => void;
};

export default function ChemicalCompoundMass({ molecularWeight, onChange }: Props) {
  const [formula, setFormula] = useState<string>('');

  const isFormulaValid = molecularWeight !== '' || formula === '';

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const val = toStandardFormula(e.target.value);
    setFormula(val);

    try {
      const molecularWeight = validateAndCalculateMolecularWeight(val);
      onChange(molecularWeight.toString());
    } catch (_) {
      onChange('');
    }
  }
  return (
    <Box gridColumn="span 2" display="grid" gridAutoFlow="column" columnGap={3}>
      <TextField
        label="e.g. C₁₀H₁₆N₅O₁₃P₃"
        value={toDisplayFormula(formula)}
        onChange={handleChange}
        error={!isFormulaValid}
        helperText={isFormulaValid ? ' ' : 'Invalid formula'}
      />
      <TextField
        value={molecularWeight?.toString() ?? ''}
        InputProps={{
          endAdornment: <InputAdornment position="end">g/mol</InputAdornment>,
          startAdornment: (
            <InputAdornment position="start">Molecular weight</InputAdornment>
          ),
        }}
        contentEditable={false}
      />
    </Box>
  );
}

const SUBSCRIPT_DIGITS = [
  { digit: '0', subscriptDigit: '\u2080' },
  { digit: '1', subscriptDigit: '\u2081' },
  { digit: '2', subscriptDigit: '\u2082' },
  { digit: '3', subscriptDigit: '\u2083' },
  { digit: '4', subscriptDigit: '\u2084' },
  { digit: '5', subscriptDigit: '\u2085' },
  { digit: '6', subscriptDigit: '\u2086' },
  { digit: '7', subscriptDigit: '\u2087' },
  { digit: '8', subscriptDigit: '\u2088' },
  { digit: '9', subscriptDigit: '\u2089' },
];

const INTERPUNCT = '\u00b7';

function createNonHydrationDigitRegex(digit: string) {
  return new RegExp(`(?<!\\.)${digit}`, 'g');
}

function toDisplayFormula(formula: string) {
  for (const { digit, subscriptDigit } of SUBSCRIPT_DIGITS) {
    formula = formula.replaceAll(createNonHydrationDigitRegex(digit), subscriptDigit);
  }

  formula = formula.replaceAll('.', INTERPUNCT);
  return formula;
}

function toStandardFormula(displayFormula: string) {
  for (const { digit, subscriptDigit } of SUBSCRIPT_DIGITS) {
    displayFormula = displayFormula.replaceAll(subscriptDigit, digit);
  }
  displayFormula = displayFormula.replaceAll(INTERPUNCT, '.');
  return displayFormula;
}
