import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { InputStep } from 'client/app/apps/protocols/InputStep';
import { Step, WorkflowSchema } from 'client/app/apps/protocols/useGetData';

type Props = {
  steps: Step[];
  workflowSchema: WorkflowSchema;
};

export const InputSteps = ({ steps, workflowSchema }: Props) => {
  const [openedInput, setOpenedInput] = useState(steps[0].id);

  const handleOpenInput = (id: string) => setOpenedInput(id);

  return (
    <Wrapper>
      {steps.map(step => (
        <InputStep
          key={step.id}
          step={step}
          isOpen={step.id === openedInput}
          onClick={() => handleOpenInput(step.id)}
          inputs={workflowSchema.inputs.filter(input => step.inputIds.includes(input.id))}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));
