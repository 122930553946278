import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MuiDivider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  calculateAbsorbance,
  calculateTransmittance,
} from 'client/app/apps/standalone-tools/absorbance-transmittance-converter/calculation';
import { validateAndConvertToDecimal } from 'client/app/apps/standalone-tools/utils';
import Button from 'common/ui/components/Button';
import Switch from 'common/ui/components/Switch';
import TextField from 'common/ui/filaments/TextField';

export default function AbsorbanceTransmittanceConverter() {
  const [checked, setChecked] = useState(false);

  const [absorbance, setAbsorbance] = useState<string | undefined>('');
  const [transmittance, setTransmittance] = useState<string | undefined>('');
  const [result, setResultState] = useState<string | undefined>('');
  const [isErrored, setIsErrored] = useState(false);

  function calculate() {
    const value = checked ? transmittance : absorbance;
    const decimalValue = validateAndConvertToDecimal(value ?? '');

    if (decimalValue !== null) {
      const result = checked
        ? calculateAbsorbance(decimalValue)
        : calculateTransmittance(decimalValue);
      setResultState(result);
    } else {
      setIsErrored(true);
      setResultState('');
    }
  }

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setAbsorbance('');
    setTransmittance('');
    setResultState('');
    setIsErrored(false);
  };

  return (
    <>
      <Typography>
        The Absorbance/Transmittance converter can be used to determine light
        transmittance from an optical density reading and vice versa. Absorbance values
        are given in arbitrary units (AU) and transmittance values are provided as a
        percentage. The converter provides values half-even rounded to 9 decimal places.
      </Typography>
      <Box display="grid" gridAutoFlow="column" justifyContent="center">
        <Box display="grid" alignContent="center">
          <Typography>Absorbance</Typography>
        </Box>
        <Switch
          checked={checked}
          onChange={handleSwitchChange}
          data-heap-tracking="standalone-tools-absorbance-transmittance-calculator-mode-toggle"
        />
        <Box display="grid" alignContent="center">
          <Typography>Transmittance</Typography>
        </Box>
      </Box>
      <FieldsContainer>
        {checked ? (
          <>
            <Label>Transmittance</Label>
            <StyledTextField
              value={transmittance}
              onChange={e => {
                setTransmittance(e.target.value);
              }}
              error={isErrored}
              helperText={
                isErrored
                  ? 'The transmittance value must be a non-negative, numeric value'
                  : ' '
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </>
        ) : (
          <>
            <Label>Absorbance</Label>
            <StyledTextField
              value={absorbance}
              onChange={e => {
                setAbsorbance(e.target.value);
              }}
              error={isErrored}
              helperText={
                isErrored
                  ? 'The absorbance value must be a non-negative, numeric value'
                  : ' '
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">AU</InputAdornment>,
              }}
            />
          </>
        )}
      </FieldsContainer>
      <Divider />
      <ResultSection>
        <Button
          variant="secondary"
          onClick={calculate}
          data-heap-tracking="standalone-tools-absorbance-transmittance-calculator-calculate"
        >
          Calculate
        </Button>
        <StyledTextField
          value={result}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{checked ? 'AU' : '%'}</InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {checked ? 'Absorbance' : 'Transmittance'}
              </InputAdornment>
            ),
          }}
          contentEditable={false}
        />
      </ResultSection>
    </>
  );
}

const FieldsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr',
  rowGap: theme.spacing(3),
}));
const Label = styled(Typography)({
  gridColumn: 'span 2',
});

const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ResultSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(4),

  marginTop: theme.spacing(4),
}));

const StyledTextField = styled(TextField)({
  '& input': {
    textAlign: 'right',
  },
});
